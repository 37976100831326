
:root {
    --drm-font-family-sans-serif: 'Roboto', sans-serif;
}

.styles-module_container__C22IZ {
    background-color: aliceblue;
    display: grid;
    /* column-gap: 50px; */
}

.styles-module_item__KMqVJ {
    padding: 5px 10px;
    line-height: 1.3;
    border-right: 1px solid #eaeaea;
    position: relative;
    padding-left: 9px;
}

.styles-module_title__KzQmE {
    font-weight: bold;
    font-size: 13px;
    white-space: nowrap;
}

.styles-module_subtitle__yRatr {
    font-size: 11px;
    white-space: nowrap;
}

.styles-module_value__eM24a {
    font-weight: bold;
    font-size: 31px;
    line-height: 1;
    padding-top: 0px;
}

.styles-module_footerRow__b49xD {
    font-size: 10px;
    opacity: 0.8;
    position: absolute;
    right: 8px;
    bottom: 5px;
}

.styles-module_source__RRMnF {
    display: block;
    text-align: right;
    color: #666;
}

.styles-module_source__RRMnF a {
    text-decoration: none;
    color: #666;
}

.styles-module_source__RRMnF img {
    width: 8px;
    margin-right: 3px;
    opacity: 0.3;
}

.styles-module_source__RRMnF:hover a {
    color: rgb(0, 0, 0);
}

.styles-module_source__RRMnF:hover img {
    opacity: 1;
}

.styles-module_date__jd2-H {
    display: block;
    text-align: right;
    color: #000;
    font-weight: normal;
}

@media only screen and (max-width: 600px) {
    .styles-module_subtitle__yRatr {
        font-size: 10px !important;
        white-space: nowrap;
    }
    .styles-module_container__C22IZ {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .styles-module_value__eM24a {
        font-weight: bold;
        font-size: 28px;
        line-height: 1;
        padding-top: 0px;
    }
    .styles-module_item__KMqVJ {
        padding-top: 2px;
        padding-bottom: 2px;
        border-bottom: 0.5px solid #dddddd;
        border-left: 0.5px solid #f5f5f5;
        padding-left: 7px;
        zoom: 0.8;
    }

    .styles-module_title__KzQmE {
        font-weight: bold;
        font-size: 12px;
        white-space: nowrap;
    }

    .styles-module_footerRow__b49xD {
        bottom: 2px;
        line-height: 1.15;
        right: 5px;
    }
}
@import '@knight-lab/timelinejs/dist/css/timeline.css';

.Timeline_tl-icon-swipe-left__f28km {
    transform: scaleX(-1);
}

.Timeline_tl-slidenav-previous__5e2IH .Timeline_tl-slidenav-title__aduim, .Timeline_tl-slidenav-next__QtBvh .Timeline_tl-slidenav-title__aduim {
    filter: alpha(opacity=75);
    -khtml-opacity: 0.75;
    -moz-opacity: 0.75;
    opacity: 0.75;
    margin-top: 10px;
    font-size: 10px !important;
}

.Timeline_tl-slidenav-previous__5e2IH .Timeline_tl-slidenav-description__CLnsl, .Timeline_tl-slidenav-next__QtBvh .Timeline_tl-slidenav-description__CLnsl {
    filter: alpha(opacity=0.4) !important;
    -khtml-opacity: 0.4 !important;
    -moz-opacity: 0.4 !important;
    opacity: 0.4 !important;
}

.Timeline_tl-timeline__wP3JT h1, .Timeline_tl-timeline__wP3JT h2, .Timeline_tl-timeline__wP3JT h3 {
    font-size: 13px;
    line-height: 1.4;
}



.Timeline_tl-timeline__wP3JT div:not(.Timeline_tl-slidenav-icon__0fRI-), 
.Timeline_tl-timeline__wP3JT span:not(.Timeline_tl-slidenav-icon__0fRI-),
.Timeline_tl-timeline__wP3JT h1,
.Timeline_tl-timeline__wP3JT h2,
.Timeline_tl-timeline__wP3JT h3, 
.Timeline_tl-timeline__wP3JT h4 {
    font-family: 'Roboto Slab', sans-serif !important;
}

.Timeline_tl-headline__VuXjy h2 {
    font-family: 'Roboto', sans-serif !important;
    font-weight: bold !important;
    font-size: 24px !important;
    line-height: 39px !important;
}

.Timeline_tl-slide-content-container__MX89F .Timeline_tl-timeline__wP3JT h1, .Timeline_tl-slide-content-container__MX89F .Timeline_tl-timeline__wP3JT h2, .Timeline_tl-slide-content-container__MX89F .Timeline_tl-timeline__wP3JT h3, .Timeline_tl-slide-content-container__MX89F .Timeline_tl-timeline__wP3JT h4, .Timeline_tl-slide-content-container__MX89F .Timeline_tl-timeline__wP3JT h5, .Timeline_tl-timeline__wP3JT h6 {
    font-family: 'Roboto', sans-serif;
    font-size: 12px !important;
    line-height: 1.4 !important;
}


#Timeline_timeline__SLtBu h1 {
    font-size: 19px;
    margin-top: 6px;
}

#Timeline_timeline__SLtBu {
    padding: 1.8%;
    padding-top: 1px !important;
}

#Timeline_timeline-embed__5vS8k,
.Timeline_tl-timeline__wP3JT {
    font-family: 'Roboto', sans-serif !important;
    margin-top: 13px;
    font-size: 12px;
}

.Timeline_tl-timegroup__XUdP6 .Timeline_tl-timegroup-message__w0FwK {
    color: #5c5c5c;
    margin-left: 53px;
    position: absolute;
    z-index: 6;
    right: 10px;
    font-weight: 600;
    margin-top: -1px;
}

.Timeline_tl-slidenav-previous__5e2IH .Timeline_tl-slidenav-content-container__A9bSA, .Timeline_tl-slidenav-next__QtBvh .Timeline_tl-slidenav-content-container__A9bSA {
    height: 200px;
    width: 100px;
    position: absolute;
    
}

.Timeline_tl-storyslider__nETze {
    margin-top: 10px;
}

.Timeline_tl-slide-text-only__NCOu9 .Timeline_tl-slide-content-container__MX89F .Timeline_tl-slide-content__XAvyI {
    text-align: center !important;
    /* margin-top: -90px !important; */
}


.Timeline_tl-medium__1aWfm .Timeline_tl-text__VtSxh h2.Timeline_tl-headline-title__lAWLo, .Timeline_tl-medium__1aWfm .Timeline_tl-text__VtSxh h2.Timeline_tl-headline__VuXjy,
.Timeline_tl-skinny__IgknR .Timeline_tl-text__VtSxh h2.Timeline_tl-headline-title__lAWLo, .Timeline_tl-skinny__IgknR .Timeline_tl-text__VtSxh h2.Timeline_tl-headline__VuXjy {
    font-size: 12px !important;
    line-height: 1.4 !important;
}

.Timeline_tl-slide-content-container__MX89F {
    display: block !important;
}

.Timeline_tl-slidenav-next__QtBvh, .Timeline_tl-slidenav-previous__5e2IH {
    top: 7px !important;
}

.Timeline_tl-layout-portrait__y-F1a .Timeline_tl-storyslider__nETze {
    box-shadow: 0px -3px 6px rgb(0 0 0 / 6%);
}

.Timeline_tl-text__VtSxh .Timeline_tl-headline-date__v0IVx, .Timeline_tl-text__VtSxh h3.Timeline_tl-headline-date__v0IVx {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 11px !important;
    line-height: 1.2 !important;
    font-weight: normal;
    margin: 0 0 3px 0;
    color: #b3b3b3;
}

.Timeline_tl-timeaxis__UoKrK .Timeline_tl-timeaxis-content-container__bYzxb .Timeline_tl-timeaxis-major__JeK0X .Timeline_tl-timeaxis-tick__FbQ1k,
.Timeline_tl-timeaxis__UoKrK .Timeline_tl-timeaxis-content-container__bYzxb .Timeline_tl-timeaxis-minor__dGSMl .Timeline_tl-timeaxis-tick__FbQ1k {
    font-size: 11px;
}


.Timeline_tl-attribution__1WZo1 {
    display: none;
}

.Timeline_tl-timeline__wP3JT p,
.Timeline_tl-text-content__LoX4q p {
    font-size: 11px !important;
}

.Timeline_tl-timemarker__0hvTC .Timeline_tl-timemarker-content-container__j0Nx- .Timeline_tl-timemarker-content__0xXdy .Timeline_tl-timemarker-text__TSZ-1 h2.Timeline_tl-headline__VuXjy, .Timeline_tl-timemarker__0hvTC .Timeline_tl-timemarker-content-container__j0Nx- .Timeline_tl-timemarker-content__0xXdy .Timeline_tl-timemarker-text__TSZ-1 h2.Timeline_tl-headline__VuXjy p {
    font-size: 11px;
    line-height: 12px;
}

.Timeline_tl-timeline__wP3JT a {
    text-decoration: none;
    font-size: 10px;
    color: #f58220;
}