/* @import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@400;700&family=Barlow:wght@400;700&family=Futura:wght@400;700&family=Lato:wght@400;700&family=Montserrat:wght@400;700&family=Open Sans:wght@400;700&family=Raleway:wght@400;700&family=Roboto:wght@400;700&family=RobotoSlab:wght@400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@400;700&family=Lato:wght@400;700&family=Montserrat:wght@400;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,300;1,400&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@400;700&family=Barlow+Condensed:wght@400;700&family=Barlow:wght@100;400;700&family=Lato:wght@400;700&family=Montserrat:wght@400;700&family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&family=Roboto+Slab:wght@400;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,300;1,400&display=swap');

.ol-map {
  min-width: 700px;
  min-height: 50px;
  width: "100%";
}
.ol-control {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 2px;
}

.ol-full-screen {
  top: 0.5em;
  right: 0.5em;
}

.ol-scale {
  visibility: visible;
}

.ol-scale-line {
  visibility: visible;
}

.ol-scale-bar {
  visibility: visible;
}

.ol-scale-line-inner {
  color: inherit !important;
}

.ol-scale-step-text {
  color: inherit !important;
}

.ol-map {
  background-color: #fafafa;
  border: 1px solid rgb(211, 211, 211);
}

.ol-overviewmap-box {
  pointer-events: none !important;
}

.mapContainer {
  -webkit-box-shadow: 0px 0px 11px -8px rgba(0,0,0,0.45);
  -moz-box-shadow: 0px 0px 11px -8px rgba(0,0,0,0.45);
  box-shadow: 0px 0px 11px -8px rgba(0,0,0,0.45);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  background-color: white;
  position: relative;
}

.logos {
  display: inline-block;
  float: left;
  margin-top: 2px;
}

.headeriMMAP .logos {
  /* float: right !important; */
  position: absolute;
  right: 0px;
}

.headerLogo {
  display: inline-block;
  height: 28px;
  padding-right: 5px;
  padding-top: 3px;
  vertical-align: middle;
  
}

.headerLogo img {
  padding-right: 4px;
}

.logoCDCF {
  height: 39px;
  margin-right: 7px;
  margin-top: -8px;
}

.logoDrc {
  height: 33px;
}

.logoDeep {
  height: 36px;
  margin-right: 2px;
  margin-top: -2px;
}

.logoDeepSmall {
  height: 45px;
  margin-right: 0px;
  margin-top: -6px;
  margin-left: -5px;
}

.logoDfs {
  height: 34px;
  margin-top: -1px;
}

.logoImmap {
  height: 45px;
  margin-top: -7px;
}

.logoUnocha {
  height: 48px;
  margin-right: -1px;
  margin-top: -9px;
}

.logoImmapBorder {
  background-color: rgb(160, 160, 160);
  display: inline-block;
  height: 34px;
  /* margin-top: 0px; */
  margin-left: 0px;
  margin-right: 44px;
  position: relative;
  width: 0.7px;
  top: -23px;
  margin-right: 8px;
  margin-right: 5px;
  margin-left: 5px;
}

.titleContainer {
  /* float: right; */
  display: inline-block;
}

.mapTitle {
  position: absolute;
  z-index: 3;
  padding: 3px 8px;
  padding-top: 2px;
  line-height: 1.26;
  display: inline-block;
  margin-left: 3px;
  width: -webkit-fill-available;
  height: 47px;
}

.mainTitle {
  font-size: 19px;
  font-weight: 700;
  white-space: nowrap;
}

.subTitle {
  font-size: 12px;
  font-weight: normal;
  color: #666;
  white-space: nowrap;
}

.dateText {
  float: right;
  color: grey;
  font-size: 11px;
  margin-top: 5px;
  margin-right: 3px;
}

.headeriMMAP .dateText {
  margin-right: 16px !important;
  margin-top: 17px !important;
}

.immapHeaderBar {
  position: absolute;
  /* height: 10px; */
  display: inline-block;
  top: 34px;
  z-index: 1000;
  width: 100%;
}

.immapHeaderBar1 {
  background-color: #9D9B9C;
  height: 5px;
  width: 80%;
  display: inline-block;
}

.immapHeaderBar2 {
  background-color: #be2126;
  height: 5px;
  display: inline-block;
  width: 20%;
}

.immapFooterBar {
  display: inline-block;
  z-index: 99999;
  width: 100%;
  position: absolute;
  /* bottom: -3vh; */
  bottom: -3.2vh;
}

.immapFooterBar1 {
  background-color: #be2126;
  height: 5px;
  width: 80%;
  display: inline-block;
}

.immapFooterBar2 {
  background-color: #9D9B9C;
  height: 5px;
  display: inline-block;
  width: 20%;

}

.immapStrapline {
  font-size: 11px;
  background-color: #FFF;
  font-weight: 700;
  color: #747274;
  padding-left: 6px;  
  font-family: 'Barlow Condensed';
  display: inline-block;
}

.immapStrapLine2 {
  color: #be2126;
  display: inline;
}

.immapStrapline img {
  height: 11px;
  padding-left: 3px;
  padding-bottom: 3px;
}

.disclaimer {
  display: inline-flex;
  float: right;
  text-align: right;
  font-size: 8.5px;
  color: grey;
  padding-top: 7px;
  padding-right: 9px;
}

.immapFooterColorBar {
  height: 12px;
}

.headeriMMAP .scalePostopRight, 
.headeriMMAP .POS-topRight {
  top: 36px !important;
}

.mapBottomRight {
  top: unset !important;
  left: unset !important;
  right: 8px;
  bottom: 8px !important;
  color: inherit !important;
  position: absolute;
  z-index: 100;
  text-align: right;
}

.mapBottomLeft {
  top: unset !important;
  left: 8px !important;
  right: unset !important;
  bottom: 20px !important;
  color: inherit !important;
  position: absolute;
  z-index: 100;
  text-align: left;
}

.mapTopRight {
  top: 64px !important;
  left: unset !important;
  right: 8px;
  bottom: unset !important;
  color: inherit !important;
  position: absolute;
  z-index: 100;
  text-align: right;
}

.mapTopLeft {
  top: 64px !important;
  left: 8px !important;
  right: unset !important;
  bottom: unset !important;
  color: inherit !important;
  position: absolute;
  z-index: 100;
  text-align: left;
}

.scalePosbottomRight {
  top: unset !important;
  left: unset !important;
  right: 8px;
  bottom: 8px !important;
  color: inherit !important;
  opacity: 0.8;
}

.POS-bottomRight {
  top: unset !important;
  left: unset !important;
  right: 8px;
  bottom: 8px !important;
  color: inherit !important;
  opacity: 0.95;
}

.scalePostopRight, .POS-topRight {
  bottom: unset !important;
  left: unset !important;
  top: 8px;
  right: 9px;
  opacity: 0.95;
  color: inherit !important;
}

.scalePosbottomLeft, .POS-bottomLeft {
  left: 8px !important;
  top: unset !important;
  right: unset !important;
  bottom: 8px;
  opacity: 0.8;
  color: inherit !important;
}

.mapFooter {
  font-size: 9px;
  padding-left: 8px;
  opacity: 0.7;
  margin-top: -19px;
}

.mapFooter .sources {
  font-weight: 300;
  display: inline;
  padding-left: 2px;
}

.mapLegend {
  position: absolute;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 11px;
  background-color: white;
  padding: 4px 6px 8px 6px;
  border-radius: 3px;
  min-width: 130px;
  margin-left: 2px;
}

.mapLegendTitle {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 13px;
}

.legendSymbol {
  width: 15px;
  height: 17px;
  display: inline-block;
  margin-top: 0px;
  vertical-align: middle;
  margin-right: 3px
}

.legendPolygonSingle {
  width: 20px;
  height: 11px;
  display: inline-block;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 5px;
  margin-left: 1px;
  vertical-align: middle;
  border: 0.5px solid rgb(224, 224, 224);
}

.legendPolygonGraduated {
  height: 12px;
  margin-bottom: 1px;
  margin-top: 2px;
  /* margin-top: 3px;
  margin-right: 2px;
  margin-left: 1px;
  vertical-align: middle;
  border: 0.5px solid rgb(224, 224, 224); */
  overflow: hidden;
}

.legendSeriesTitle {
  display: inline-block;
  padding-top: 2px;
  white-space: nowrap;
}

.legendPolygonScaleUnits {
  height: 10px;
  font-size: 7px;
  color: #666;
  margin-top: -4px;
}

.legendPolygonScaleUnit1 {
  display: inline;
  margin-left: 0px;
}

.legendPolygonScaleUnit2 {
  float: right;
  display: inline;
}

.embedMap {
  box-shadow: none;
}

.exportButtons {
  margin-top: 8px;
  transform: scale(0.8);
  transform-origin: top left;
}

@media only screen and (max-width: 600px) {
  .mapLegend {
    margin-top: 0px;
    zoom: 0.75;
    background-color: rgba(255,255,255,0.7);
    top: unset !important;
    bottom: 6px;
  }
  .header {
    zoom: 0.62;
  }
  .immapFooterBar {
    zoom: 0.62;
  }

  .mapFooter {
    bottom: 0px;
    left: 0px;
    padding-left: 3px;
    bottom: 0px !important;
    font-size: 8px;
  }
  .dashboard {
    margin-top: 253px !important;
    height: calc(100vh - 210px) !important;
    padding-top: 0px !important;
    margin-bottom: 7px !important;
  }
  .headeriMMAP .POS-topRight {
    top: 10px !important;
    zoom: 0.7;
  }
}