.layersPanel {
    background-color:rgb(241, 241, 241);
    padding: 6px 8px;
    border-right: 1px solid #fbfbfb;
    padding-left: 5px;
}

.layersPanel h1 {
    padding: 0px;
    margin: 0px;
    font-size: 18px;
    color: rgb(59, 59, 59);
    padding-left: 4px;
}

.MapLayers_row div {
    display: inline-block;
}

.layersPanelBody {
    margin-top: 3px;
    padding-left: 5px;
}

.layersPanelContainer {
    height: 100%;
}

.layersPanelBg {
    min-height: 200px;
    cursor: pointer;
}

.MapLayers_row:hover {
    background-color: #f7f7f769;
}

.MapLayers_row {
    height: 36px;
    font-size: 12px;
    padding-left: 2px;
    padding-right: 3px;
    padding-top: 1px;
    cursor: pointer;
    white-space: nowrap;
}

.MapLayers_icon img {
    width: 20px;
    margin-top: 5px;
    margin-left: 5px;
    opacity: 0.2
}

.MapLayers_icon_container {
    display: inline-block;
    width: 32px;
    height: 33px;
    margin: 2px 0px;
}

.MapLayers_icon {
    background-color: #eaeaea;
    display: inline-block;
    width: 30px;
    height: 30px;
    /* margin: 3px 0px; */
    font-size: 8px;
    border-radius: 30px;
}

.MapLayers_title {
    display: inline-block;
    padding-left: 6px;
    padding-top: 10px;
    vertical-align: top;
    width: 185px;
    overflow: hidden;
}

.MapLayers_buttons {
    display: block;
    font-size: 9px;
    float: right;
    margin-top: 5px;
    cursor: pointer;
    opacity: 1;
    padding-right: 6px;
}

.MapLayers_buttons img {
    width: 15px;
    margin-top: 5px;
    margin-left: 5px;
    opacity: 0.2
}

.MapLayers_buttons_forward,
.MapLayers_buttons_back,
.MapLayers_buttons_show,
.MapLayers_buttons_remove {
opacity: 0;
}

.hovered .MapLayers_buttons_forward,
.hovered .MapLayers_buttons_back,
.hovered .MapLayers_buttons_show,
.hovered .MapLayers_buttons_remove {
    opacity: 1 !important;
}

.MapLayers_buttons_hidden   {
    opacity: 1 !important;

}

.active {
    font-weight: bold;
}