.root {
  width:100%;margin:0px auto;overflow:hidden;height:100%;
  height: 100vh;
  overflow: hidden;
}

:focus {outline:0 !important;}

.printing .ol-map {
  /* height: calc(100% - 50px) !important; */
  margin-top: 100px !important;
}

.body {
  width:calc((100%)*1.0309278350515465);height:calc((100%)*1.0309278350515465);transform:scale(0.97);transform-origin:0 0;
  max-height: 100%;
}


.header header {
  width: 550px;
  margin-left: 235px;
}

.header a {
  font-size: 14px !important;
  margin-top: 2px;

}

.header [data-scope='tabs'][data-part='list'] {
  /* background-color: #295fa0; */
  /* text-transform: uppercase; */
  display: grid;
  width: 100%;
  grid-template-columns: max-content max-content max-content max-content max-content max-content;
  /* grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); */
}

.header [data-part='trigger'] {
  /* min-width: 70px; */
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 5px solid transparent;

  height: 50px;
}

.header [data-part='trigger'] a {
  line-height: 3.2;
}

.header [data-part='trigger'][aria-selected='true']{
  /* background-color: #667f9d; */
  border: none;
  /* border-bottom: 0px solid var(--primary-color); */
  border-bottom: 5px solid rgb(0 0 0 / 29%);
  text-decoration: none;
  text-transform: uppercase;
  color: #FFF;
  background-color: var(--primary-color);
}

.header [data-part='trigger'][aria-selected='true'] a {
  color: #FFF;
}

.header .conflictTab[aria-selected='true']{
  /* border-bottom: 5px solid var(--primary-color); */
  border-bottom: 5px solid rgb(0 0 0 / 29%);
  color: #FFF;
  background-color: var(--primary-color);
  font-weight: bold;
}

.header .displacementTab[aria-selected='true']{
  /* border-bottom: 5px solid rgb(65, 143, 222); */
  border-bottom: 5px solid rgb(0 0 0 / 29%);
  color: #FFF;
  background-color: rgb(65, 143, 222);
  font-weight: bold;
}

.header .foodSecurityTab[aria-selected='true']{
  /* border-bottom: 5px solid rgb(229, 106, 84); */
  border-bottom: 5px solid rgb(0 0 0 / 29%);
  color: #FFF;
  background-color: rgb(229, 106, 84);
  font-weight: bold;
}

.header .protectionTab[aria-selected='true']{
  /* border-bottom: 5px solid #295fa0; */
  border-bottom: 5px solid rgb(0 0 0 / 29%);
  background-color: #295fa0;
  color: #FFF;
  font-weight: bold;
}

div {
  user-select: none; 
}

.subViews [data-scope='tabs'][data-part='list'] {
  /* background-color: #295fa0; */
  /* text-transform: uppercase; */
  display: grid;
  width: 100%;
  grid-template-columns: auto max-content max-content max-content auto;
  /* grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); */
}

[data-part='trigger']{
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  border-bottom: 3px solid #FFF;
  /* width: 20%; */
  /* min-width: 70px; */
}

[data-part='trigger'] a {
  text-decoration: none;
  color: grey;
}

[data-part='trigger'][aria-selected='true']{
  /* background-color: #667f9d; */
  border: none;
  border-bottom: 3px solid var(--primary-color);
  text-decoration: none;
  text-transform: uppercase;
}

[data-part='trigger'][aria-selected='true'] a {
  color: var(--primary-color);
  color: black;
  /* font-weight: bold; */
}

.documents {
  background-color: white;
}

.subViews {
  z-index: 99999;
  position: absolute;
  top: 129px;
  left: 5px;
  background-color: white;
  height: 19px;
  width: 370px;
}

.subViews a {
  font-size: 12px;
  line-height: 1;
  font-family: 'Barlow Condensed';
  min-height: 20px;
  height: 20px;
  padding: 4px;
  vertical-align: middle;
}

.excludedFromHeatmap {
  z-index: 99999;
  position: absolute;
  bottom: 24px;
  left: 8px;
  background-color: white;
  font-size: 11px;
  line-height: 1;
  font-family: 'Barlow Condensed';
  padding: 4px;
  opacity: 0.8;
  color: #1e1e1e;
  text-decoration: italic;
  border: 0.5px solid rgb(209, 209, 209);
  align-items: center;
}

.excludedFromHeatmap span {
  color:rgb(190, 33, 38);
  font-weight: bold;
  font-size: 12px;
}


.admSelectContainer {
  position: absolute;
  top: 128px;
  left: 391px;
}
.adm1Select {
}

.adm2Select {
  margin-left: 7px;
}

.gridSelect {
  margin-left: 7px;
}

.admSelect {
  z-index: 99999;
  background-color: white;
  border-radius: 3px;
  display: inline-block;
}

.gridColsSelect {
  z-index: 99999;
  background-color: white;
  border-radius: 3px;
  margin-left: 7px;
  display: inline-block;
  width: 50px !important;
}

.admSelect .MuiSvgIcon-root {
  right: 2px !important;
}

.admSelect .MuiFormControl-root {
  margin: 0px !important;
}
.admSelect div {
  font-size: 10px;
  font-family: 'Barlow Condensed';
  margin: 0px !important;
}

.admSelect label.Mui-focused {
  margin-top: 2px !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.1);
}

.admSelect fieldset {
  border-width: 1px !important;
  border-color: rgba(0, 0, 0, 0.1);
}

.admSelect fieldset legend span {
  display: none;
}

.admSelect .MuiOutlinedInput-input {
  padding: 2px !important;
}
.admSelect .MuiSelect-select {
  color: rgb(190, 33, 38);
  font-family: 'Barlow Condensed';
  min-height: 12px !important;
  height: 12px; 
}

.admSelect b {
  font-weight: 500 !important;
  color: #000 !important;
}

.admSelect span {
  color: #666;
}

li {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

ul {
  padding-top: 0px !important;
  border-radius: 0px !important;
}

ul div {
  border-radius: 0px !important;
}

.adm1Select div[role=button] {
  height: 10px !important;
  min-height: unset !important;
  padding-top: 6px;
  padding-bottom: 11px;
  padding-left: 8px;
}

.exportButton {
  z-index: 99999;
    position: absolute;
    right: 262px;
    top: 24px;
    zoom: 0.6;
    /* display: none; */
}

.saveButton {
  z-index: 99999;
  position: absolute;
  right: 394px;
  top: 28px;
  zoom: 0.6;
}
.embedIframe {
  position: absolute;
  z-index: 10000;
  top: 14px;
  right: 134px;
}

.embedIframe:hover {
  opacity: 0.92;
}

.total {
    position: absolute;
    z-index: 10;
    top: 64px;
    left: 10px;
    font-family: 'Barlow Condensed';
  }
  
  .totalTitle {
    font-size: 14px;
    font-weight: 700;
    color: #151515;
  }
  
  .totalValue {
    font-weight: bold;
    font-size: 30px;
    color: #be2126;
  }

.acledDateRangeSlider {
    z-index: 1000;
    position: relative;
    width: 320px;
    top: 0px;
    left: -155px;
    height: 95px;
}

.acledControls {
  z-index: 1000;
  position: absolute;
  width: 170px;
  top: 128px;
  right: 39px;
}

.barSpark {
  position: relative;
  z-index: 1;
  width: 100%;
}

.tooltipDiv {
  padding: 2px 3px !important;
  border-radius: 0px !important;
  background-color: white !important;
  z-index: 1001;
}

.barTooltipDiv {
  font-family: 'Barlow Condensed';
  font-size: 12px !important;
  border: 0.5px solid rgb(201, 201, 201);
  padding: 2px 3px !important;
  border-radius: 0px !important;
  line-height: 1.1;
  background-color: rgba(255,255,255,1) !important;
}

svg rect { outline:none; }

svg rect:hover {
  opacity: 0.8; 
}

.tooltipVal {
  font-weight: bold;
  font-size: 16px;
  color: #be2126;
  display: inline-block;
  padding-right: 3px;
}

.tooltipLabl {
  display: inline-block;
  font-size: 9.5px;
}

.monthPicker {
  margin-top: -13px;
  padding-bottom: 4px;
  position: relative;
  z-index: 100;
  white-space: nowrap;
}

.monthPicker div {
  display: inline-block;
  /* width: 30.7px; */
  font-family: 'Barlow Condensed';
  font-size: 9px;
  color: rgb(168, 168, 168);
  text-align: center;
  cursor: pointer;
  /* margin-top: -10px; */
}

.monthPicker button {
  display: inline-block;
  width: 20px;
  font-family: 'Barlow Condensed';
  border: none;
  background-color: white;
  font-size: 9px;
  color: rgb(168, 168, 168);
  text-align: center;
  cursor: pointer;
  /* margin-top: -10px; */
}

.monthPicker button:hover {
  /* font-weight: bold; */
  color: rgb(56, 56, 56);
  /* margin-top: -10px; */
}

.acledDateRangeSliderInner {
  background-color: rgba(255,255,255,0.95);
  padding: 1.5px 8px 0px 10px;
  /* border-radius: 5px; */
  /* border: 1px solid #e8e8e8; */
}

.selectedDateRangeTitle {
    font-size: 12px;
    font-weight: bold;
    display: inline-block;
    font-family: 'Barlow Condensed';

}
.selectedDateRange {
    font-size: 12px;
    color: grey;
    text-align: right;
    display: inline-block;
    width: fit-content;
    padding-left: 8px;
    font-family: 'Barlow Condensed';
    /* background-color: var(--primary-color);  */
    color: var(--primary-color);
    border-radius: -1px;
    margin-left: 2px;
    padding-right: 3px;
    line-height: 1.2;
    margin-top: 2px;
}

.acledWeighted {
  font-size: 11px;
    font-family: 'Barlow Condensed';
    /* margin-left: -20px; */
    margin-top: 0px;
  padding: 0px;
  display: inline-block;
  padding-right: 7px;
  width: 168px;
  text-align: left;
  color: grey;
  top: 3px;
  position: relative;
}

.weightedCheck>span {
  padding-left: 4px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.weightedCheck {
  margin-left: 0px;
  background-color: rgba(255,255,255,0.95);
}

.acledMapType {
  width: 100%;
  display: grid;
  grid-template-columns: 50vw 1fr;
  position: relative;
  right: 70px;
  top: 0px;
}

.acledActorSelect {
  background-color: rgba(255,255,255,0.95);
  display: none;
  padding-left: 5px;
  max-width: calc(50vw - -7px);
  width: 100%;
}

.acledActorSelect .MuiFormControl-root {
  margin: 0px !important;
}
.acledActorSelect div {
  font-size: 10px;
  font-family: 'Barlow Condensed';
  margin: 0px !important;

}

.acledActorSelect label.Mui-focused {
  margin-top: 2px !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.1);
}

.acledActorSelect fieldset {
  border-width: 1px !important;
  border-color: rgba(0, 0, 0, 0.1);
}

.acledActorSelect fieldset legend span {
  display: none;
}

.acledActorSelect .MuiSelect-select {
  color: rgb(190, 33, 38);
  font-family: 'Barlow Condensed';
}

.acledActorSelect div[role=button] {
  height: 10px !important;
  min-height: unset !important;
  padding-top: 6px;
  padding-bottom: 11px;
  padding-left: 8px;
}

li {
  padding-left: 3px !important;
  min-height: unset !important;
}

li span {
  font-size: 10px !important;
  padding: 0px 5px 0px 0px !important;
  font-family: 'Barlow Condensed' !important;
}

.gridColsSelect li {
  padding-left: 5px !important;
}

.actorSelectNew {
  width: 100%;
  max-width: 100%;
}

.actorSelectNew .rmsc .dropdown-heading {
  height: 24px !important;
}

.MuiCheckbox-root {
  font-size: 10px !important;
}
.acledActorToggle {
  font-size: 12px;
  font-family: 'Barlow Condensed';
  background-color: none;
  padding: 0px;
  border-radius: 0px;
  display: block;
  margin-top: 5px;
}

.acledEventType {
  font-size: 12px;
  font-family: 'Barlow Condensed';
  background-color: none;
  padding: 0px;
  border-radius: 0px;
  display: block;
  margin-top: 7px;
  width: fit-content;
}

.acledEventType button {
  font-size: 11px;
  font-family: 'Barlow Condensed' !important;
  line-height: 1;
  white-space: nowrap;
  width: 169px;
  padding: 6px 3px;
}

.actorButton {
  font-size: 11px;
  font-family: 'Barlow Condensed' !important;
  line-height: 1;
  white-space: nowrap;
  width: 169px;
  line-height: 1;
  padding: 6px 3px;
}

.actorTitle {
  transform: rotate(-90deg);
  position: absolute;
  top: 314px;
  right: -33px;
  text-transform: uppercase;
  color: #616161;
}

.eventTypeTitle {
  transform: rotate(-90deg);
  position: absolute;
  top: 190px;
  right: -43px;
  text-transform: uppercase;
  color: #616161;
}

.conflictToggleTitle {
  transform: rotate(-90deg);
  position: absolute;
  top: 12px;
  right: -99px;
  text-transform: uppercase;
  color: #616161;
  font-size: 12px;
  font-family: 'Barlow Condensed';
}

.displacementToggleTitle {
  transform: rotate(-90deg);
  position: absolute;
  top: 84px;
  right: -13px;
  text-transform: uppercase;
  color: #616161;
  font-size: 12px;
  font-family: 'Barlow Condensed';
}



.acledEventType div,
.acledActorToggle div {
  background-color: rgba(255,255,255,0.95);
}

button {
  border-radius: 0px !important;
}

.acledActorToggle button {
  font-size: 11px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.acledEventType button img,
.actorButton img {
  height: 12px;
  padding-right: 6px;
  opacity: 0.8;
}

.dtmSvg {
  position: relative;
  right: 85px;
  height: 84px;
  width: 325px;
  background-color: rgba(255,255,255,0.95);
  padding: 3px;
  top: -22px;
}

.dtmSvg svg {
  width: 100%;
  margin-top: -8px;
}

.dtmSvg svg text {
  text-transform: uppercase;
}

.dtmSvgTitle {
  font-weight: bold;
  font-size: 12px;
  position: relative; 
  left: 5px;
  top: 2px;
}

.foodSecurityMainTitle {
  z-index: 1000;
  position: absolute;
  width: auto;
  top: 133px;
  text-align: right;
  right: 42px;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  font-family: 'Barlow Condensed';
  background-color: white;
  padding-right: 6px;
  padding-left: 6px;
}

.foodSecurityTotals {
  z-index: 1000;
  position: absolute;
  width: 205px;
  top: 154px;
  right: 42px;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  font-family: 'Barlow Condensed';
  background-color: white;
  padding-left: 7px;
  padding-bottom: 4px;
  padding-top: 2px;
}

.foodSecurityTotals div {
  vertical-align: middle;
}

.foodSecurityRowDotted {
  border-top: 0.5px dotted rgb(170, 170, 170);
  margin-right: 5px;
}

.foodSecurityBox {
  border: 0.5px solid grey;
  width: 15px;
  height: 10px;
  margin-right: 4px;
  display: inline-block;
  margin-bottom: 1px;
}

.foodSecurityTitle {
  display: inline-block;
  font-weight: normal;
  width: 49px;
  height: 17px;
}

.foodSecurityValue {
  display: inline-block;
  font-weight: bold;
  width: 38px;
  text-align: right;
  font-size: 14px;
  color: rgb(229 106 84);
}

.protLocalities {
  margin-top: 1px;
  /* border-top: 0.5px solid rgb(207, 207, 207); */
  padding-bottom: 3px;
  margin-right: 5px;
}

.protLocalitiesValue {
  display: inline-block;
  text-align: right;
  float: right;
  /* color: orangered; */
  font-size: 15px;
  font-weight: bold;
  color: #295fa0;
}

.foodSecurityTotalP3Pop {
  margin-top: 1px;
  border-top: 0.5px solid rgb(207, 207, 207);
  padding-bottom: 3px;
  margin-right: 5px;
}

.foodSecurityTotalP3Population {
  display: inline-block;
  font-weight: normal;
}
.foodSecurityTotalP3PopulationValue {
  display: inline-block;
  text-align: right;
  float: right;
  /* color: orangered; */
  font-size: 15px;
  font-weight: bold;
  color: rgb(229 106 84);
}

.foodSecurityTotalPop {
  margin-top: 1px;
  border-top: 0.5px solid rgb(132, 132, 132);
  border-bottom: 0.5px solid rgb(131, 131, 131);
  padding-bottom: 3px;
  margin-right: 5px;
}

.test {
  background-color: #e8e8e8;
}
.foodSecurityTotalPopulation {
  display: inline-block;
}
.foodSecurityTotalPopulationValue {
  display: inline-block;
  text-align: right;
  float: right;
  /* color: orangered; */
  font-size: 15px;
  font-weight: bold;
  color: grey;
}

.loader .foodSecurityValue {
  color: transparent !important;
}

.foodSecurityPercent {
  display: inline-block;
  font-weight: normal;
  width: 41px;
  text-align: center;
}

.foodSecurityHeaderRow {
  color: grey;
  font-weight: normal;
  font-size: 10px;
}

.foodSecurityHeaderTitle {
  width: 78px;
  color: grey;
  display: inline-block;
  font-weight: normal;
}
.foodSecurityHeaderValue {
  display: inline-block;
  width: 37px;
  text-align: right;
  position: relative;
  top: 0px;
}

.dashboard {
  display: block;
}

.map {
  display: block;
}

.foodSecurityHeaderPercent {
  display: inline-block;
  width: 26px;
  padding-left: 6px;
  text-align: center;
  padding-right: 5px;
  position: relative;
  top: 0px;
}

.foodSecurityHeaderPopulation {
  display: inline-block;
  padding-left: 6px;
  position: absolute;
  top: -1px;
  right: 23px;

}

.foodSecurityHeaderLocalities {
  display: inline-block;
  width: 41px;
  text-align: left;
}

.foodSecurityLocalities {
  display: inline-block;
  font-weight: normal;
  width: 47px;
  text-align: center;
  padding-left: 4px;
}
.loader .foodSecurityPercent {
  color: transparent !important;
}

.foodSecurityBarRow {
  padding-right: 5px;
}

.foodSecurityBarRow div {
  display: inline-block;
  height: 10px;
}

.foodSecurityBarChart {
  font-weight: normal;
  margin-top: 4px;
  font-size: 11px;
  line-height: 1.15;
}

.foodSecurityBarChartTitle {
  font-weight: normal;
  color: grey;
  font-size: 10px;
}

.foodSecurityStateName {
  width: 64px;
  display: inline-block;
}

.foodSecurityStateName {
  display: inline-block;
}

.foodSecurityStateValue {
  display: inline-block;
  font-size: 11px;
  /* color: rgb(229 106 84); */
  /* font-weight: bold; */

}

.foodSecurityStateBar {
  background-color: rgb(229 106 84);
  display: inline-block;
  height: 9px;
  margin-top: 1px;
  margin-right: 4px;
}

.protStateBar {
  background-color: #295fa0;
  display: inline-block;
  height: 9px;
  margin-top: 1px;
  margin-right: 4px;
}

.displacementControls {
  z-index: 1000;
  position: absolute;
  width: 257px;
  top: 151px;
  right: 23px;
  font-size: 11px;
  display: inline-block;
  font-family: 'Barlow Condensed';
}

.displacementControlsUpper {
  background-color: rgba(255,255,255,0.95);
  padding: 8px 15px 10px 10px;
  border-radius: 0px;
  margin-top: -30px;
  margin-right: 0 px;
  float: right;
}

.legendCircles svg{
  filter: saturate(0%) !important;
}

.conflictToggle {
  font-size: 12px;
  font-family: 'Barlow Condensed';
  background-color: rgba(255,255,255,0.95);
  padding: 0px;
  border-radius: 0px;
  display: inline-block;
  margin-top: 5px;
  width: fit-content;
}

.conflictToggle button {
  font-size: 11px;
  font-family: 'Barlow Condensed' !important;
  line-height: 1;
  white-space: nowrap;
  padding: 7px 4.6px;
}

.conflictToggle button img {
  height: 12px;
  padding-right: 3px;
  opacity: 0.8;
}

.dashboardRadiusInner {
  display: inline-block;
  width: 154px;
  background-color: rgba(255,255,255,0.95);
  padding: 0px 7px;
  margin-top: 6px;
  border: 1px solid rgba(223, 223, 223, 0.8);
  height: 47px;
  border-radius: 0px;
}

.dashboardRadiusTitle {
  text-align: left;
  font-size: 12px;
  font-family: 'Barlow Condensed';
  padding-top: 3px;
}

.displacementToggle {
  font-size: 12px;
  font-family: 'Barlow Condensed';
  background-color: rgba(255,255,255,0.95);
  padding: 0px;
  border-radius: 0px;
  display: inline-block;
  margin-top: 10px;
}

.displacementToggle button {
  font-size: 11px;
  font-family: 'Barlow Condensed' !important;
  line-height: 1;
  white-space: nowrap;
}

.displacementToggle button img {
  height: 12px;
  padding-right: 6px;
  opacity: 0.8;
}

.kpi {
  position: absolute;
  top: 50px;
  z-index: 99;
  width: 100%;
  font-family: 'Barlow Condensed';
  box-shadow: rgba(0,0,0,0.215) 0px 0px 3px;
}

/* mapgrid */

.adm2Active {
  fill: red !important;
}

.mapgrid {
  font-family: 'Barlow Condensed';
}

.mapGridMain {
  margin-top: 28.5px;
  margin-left: 5px;
  
}

.mapGridContainer {
  display: inline-block;
  /* height: 49vw; */
  margin-top: 2.8px;
  /* padding-right: 0.7vw; */
  zoom: 0.98;
  margin-right: 7px;
}

.gridBarLegend {
  zoom: 0.8;
}

.mapGridOuter {
 display: inline-block;
 /* border: 1px solid rgb(104, 104, 106); */
 width: 100%;
 box-shadow: 0px 1px 10px 4px rgb(13 13 13 / 1%);
}

.mapGridInner {
  border-top: 1px solid #eeeeee;
  position: relative;
}

.mapGridKpi {
  z-index: 9;
  position: relative;
  border-bottom: 1px solid white;
  box-shadow: 0px 1px 1px 0px whitesmoke;
}
.mapGridVizzard {
  z-index: 9;
  aspect-ratio: 1.3333;

}
.mapTitle {
  font-weight: normal;
  font-family: 'Barlow Condensed';
}

.mapSource {
  font-weight: normal;
  font-family: 'Barlow Condensed';
  font-size: 11px;
  color: grey;
}

.tooltipTitle {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
}

.tooltipSubTitle {
  display: inline-block;
  color: grey;
  font-size: 10px;
  padding-left: 4px;
}

.tooltipAltTitle {
  display: inline-block;
  font-size: 9.7px;
  text-transform: uppercase;
}

.tooltipAltSubTitle {
  display: inline-block;
  color: grey;
  font-size: 9px;
  padding-left: 2px;

}

.tooltipLabel {
  font-size: 10px;
  width: 100px;
  display: inline-block;
  font-size: 9.5px;

}

.tooltipValue {
  font-weight: bold;
  font-size: 16px;
  color: #be2126;
  display: inline-block;
  padding-right: 3px;
  font-size: 11px;
  width: 30px;
  text-align: right;
}

.legendPolygonGraduated {
  height: 10px;
  margin-bottom: 1px;
  margin-top: 2px;
  /* margin-top: 3px;
  margin-right: 2px;
  margin-left: 1px;
  vertical-align: middle;
  border: 0.5px solid rgb(224, 224, 224); */
  /* overflow: hidden; */
}

.legendSeriesTitle {
  display: inline-block;
  padding-top: 1px;
  padding-bottom: 1px;
  white-space: nowrap;
  width: 100%;
}

.legendPolygonScaleUnits {
  height: 10px;
  font-size: 10px;
  color: #2b2b2b;
  margin-top: 6px;
}

.legendPolygonScaleUnit1 {
  display: inline;
  margin-left: 0px;
}

.legendPolygonScaleUnit2 {
  float: right;
  display: inline;
}

.subViews .bTitle {
  display: none;
}

@media only screen and (max-width: 600px) {

  .body {
    width:calc((100%)*1.075268817204301);height:calc((100%)*1.075268817204301);transform:scale(0.93);transform-origin:0 0;
    max-height: 100%;
  }

  .header header {
    width: 100%;
    margin-left: 0px;
    top: 46px !important;
    border-bottom: 1px solid #e7e7e7;
    background: white;
  }

  .header [data-scope='tabs'][data-part='list'] {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  .header [data-part='trigger'] {
    height: 31px;
    font-size: 12px;
  }

  .header [data-part='trigger'] a {
    font-size: 15px !important;
    line-height: 1.8;
  }
  
  .actorButton {
    width: 155px;
    font-size: 11px !important;
  }

  .acledDateRangeSlider {
    z-index: 1000;
    position: absolute;
    width: 108%;
    top: -65px;
    left: 0px;
    padding-right: 5px;
    height: 95px;
    border-top: 0.5px solid #dddddd;
    /* zoom: 0.823; */
    border-bottom: 0.5px solid #dddddd;
    /* box-shadow: rgba(0,0,0,0.01215) 0px 0px 2px; */
  }

  .subViews {
    border-bottom: 1px solid #e5e5e5;
    top: 151px;
    left: 0px;
    width: 100%;
    zoom: 0.9;
    height: 21px;
  }

  .subViews .bTitle {
    font-size: 12px;
    padding-left: 6px;
    display: inline-block;
    position: relative;
    top: -1px;
    font-weight: bold;
  
  }

  .subViews [data-part="root"] {
    padding-left: 64px;
    margin-top: -19px;
  }
 
  .kpi {
    box-shadow: unset;
  }
  .barSpark {
    margin-top: 3px;
  }

  .barSpark svg {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .dashboardRadius {
    text-align: left;
    width: 116px;
    top: 33px;
    right: 9px;
    position: absolute;
    z-index: 99999;
  }

  .dashboardRadiusInner {
    margin-top: 10px;
    border-radius: 0px;
    border: 0px solid #e0e0e0;
    width: 100%;
    margin-top: 4px;
    padding: 0px 5px;
    height: 25px;
  }
  
  .dashboardRadiusInner>span {
    padding: 6px 0 !important;
    top: -3px;
    position: relative;
  }

  .conflictToggle button:last-child {
    width: 60px;
  }

  .dashboardRadiusTitle {
    font-size: 8px;
    color: grey;
    padding-top: 1px;
  }

  .acledWeighted {
    width: 94px;
    font-size: 10px;
    zoom: 0.8;
    position: absolute;
    top: -71px;
    right: -48px;
    padding-top: 2px;
    z-index: 8888;
  }

  .weightedCheck>span {
    padding-right: 2px;
  }

  .conflictToggle {
    /* float: left; */
    margin-top: 0px;
    zoom: 0.93;
  }

  .acledEventType {
    /* float: left; */
    zoom: 1;
    margin-top: 12px;
  }

  .acledEventType button {
    width: auto;
    font-size: 10px;
    padding-right: 7px;
  };

  .header {
    zoom: 0.62;
  }

  .kpi {
    top: 48px;
    zoom: 0.98;
  }

  .eventTypeTitle, 
  .conflictToggleTitle,
  .actorTitle {
    transform: unset;
    position: unset;
    font-weight: bold;
    padding-bottom: 6px;
    text-align: left;
    padding-left: 3px;
    font-family: 'Barlow Condensed';
  }

  .eventTypeTitle {
    font-size: 10px;
  }

  .actorTitle {
    padding-left: 2px;
    display: flex;
    padding-top: 5px;
  }

  .acledControls {
    z-index: 1000;
    position: absolute;
    top: 221px;
    left: 0px;
    zoom: 1;
    padding-top: 10px;
    padding-left: 5px;
    width: calc(100% - 43px);
  }

  .acledControls br {
    line-height: 0.5;
    display: none;
  }

  .acledDateRangeSliderInner {
    background-color: rgba(255,255,255,1);
    padding-top: 3px;
    margin-top: 5px;
    border-radius: 0px;
    border: 0px solid #e8e8e8;
    padding-left: 5px;
    padding-right: 0px;
  }

  .acledDateRangeSliderInner button {
    font-size: 8px;
  }

  .acledActorToggle {
    display: none;
  }
  
  .acledActorSelect {
    /* display: inline-block; */
    display: none;
  }

  .acledMapType {
    right: unset;
    left: 0px;
    top: 26px;
  }
  
  .monthPicker {
    margin-top: -14px;
    padding-bottom: 0px;
  }

  .selectedDateRangeTitle {
    font-size: 10px;
  }

  .selectedDateRange {
    font-size: 10px;
    padding-left: 4px;
  }

  header {
    zoom: 0.62;
  }

  .subViews a {
    font-size: 11px;
  }

  .excludedFromHeatmap {
    left: 429px;
    zoom: 0.6;
    bottom: unset;
    display: inline-block;
    width: 259px;
    bottom: -73px;
    border: none;
    background-color: transparent;
  }

  .displacementControls {
    left: 0px;
    padding: 5px;
    padding-bottom: 0px;
    background: white;
    border-bottom: 0.5px solid #dddddd;
    width: 97%;
    top: 171px;
    /* overflow: hidden; */
  }

  .dtmSvg {
    position: unset;
    margin-top: -6px;
    width: 99%;
    height: 76px;
    overflow: hidden;
  }

  .dtmSvgTitle {
    left: 9px;
    top: 0px;
    font-size: 10px;
  }

  .displacementControlsUpper {
    position: absolute;
    top: 141px;
    left: 0px;
    zoom: 0.8;
    padding-top: 3px;
    line-height: 0;
  }

  .embedIframe {
    top: 6px;
    right: 41px;
  }

  .displacementToggle {
    margin-top: 14px;
  }

  .conflictToggleTitle {
    display: none;
  }

  .foodSecurityTotals {
    display: none;
  }

  .foodSecurityMainTitle {
    right: unset;
    left: 0px;
    top: 174px;
    font-size: 10px;
  }

  .admSelectContainer {
    position: relative;
    top: 262px;
    left: 7px;
    zoom: 0.65;
  }
  
  .gridColsSelectTitle,
  .gridColsSelect {
    display: none;
  }

  .subViews {
    top: 164px;
  }

  .mapGridMain {
    margin-top: 32.5px;
  }

}

@media only screen and (min-width: 1000px) {
  body {
   zoom: 1;
  }
}

