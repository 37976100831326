.blur0 {filter: blur(0px);}
.blur1 {filter: blur(1px);}
.blur2 {filter: blur(2px);}
.blur3 {filter: blur(3px);}
.blur4 {filter: blur(4px);}
.blur5 {filter: blur(5px);}
.blur6 {filter: blur(6px);}
.blur7 {filter: blur(7px);}
.blur8 {filter: blur(8px);}
.blur9 {filter: blur(9px);}
.blur10 {filter: blur(10px);}
.blur11 {filter: blur(11px);}
.blur12 {filter: blur(12px);}
.blur13 {filter: blur(13px);}
.blur14 {filter: blur(14px);}
.blur15 {filter: blur(15px);}
.blur16 {filter: blur(16px);}
.blur17 {filter: blur(17px);}
.blur18 {filter: blur(18px);}
.blur19 {filter: blur(19px);}
.blur20 {filter: blur(20px);}

.blur {
  -webkit-box-shadow: 0px 0px 24px 33px rgba(255,255,255,1);
  -moz-box-shadow: 0px 0px 24px 33px rgba(255,255,255,1);
  box-shadow: 0px 0px 24px 33px rgba(255,255,255,1);
}