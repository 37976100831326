body {
  margin: 0;
  font-family: "Barlow Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTabs-indicator {
  background-color: #00000030 !important;
}

.MuiSelect-icon {
  right: 2px !important;
}

header a {
  font-family: 'Barlow Condensed' !important;
}

.tooltip {
  /* border-radius: 0px; */
  border: 0.5px solid rgb(192, 192, 192);
  background-color: rgb(255, 255, 255);
  padding: 2px 4px;
  font-size: 12px;
  line-height: 1.13;
}

.tooltip b {
  font-size: 16px;
}

.printing .ol-map {
  margin-top: 40px !important;
}

.printing .documents {
  display: none;
}

.printing #bottomRight {
  bottom: -60px !important;
}

.printing .mapFooter {
  bottom: 2px !important;
}

.printing button {
  display: none !important;
}