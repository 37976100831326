.container {
    width: 100%;
    position: relative;
    top: 55px;
    z-index: 9999;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #FFF;
}
.documents {
    box-shadow: none !important;
    font-family: 'Barlow Condensed';
    background-color: unset;
}

.documents div {
    box-shadow: none !important;
    min-height: 0px;
}

.documents th {
    color: grey;
    font-size: 10px !important;
}

.documents td,
.documents th,
.documents tbody {
    font-family: 'Barlow Condensed';
    padding: 1.5px;
    font-size: 12px;
    line-height: 1.05;
    padding-left: 7px;
    padding-right: 7px;
    /* vertical-align: top; */
}

.documents a {
    text-decoration: none;
    color: #000;
}

.divTitle {
    margin: 0px;
    font-size: 14px;
    font-weight: bold;
    padding-left: 7px;
    padding-bottom: 2px;
    position: absolute;
    top: -3px;
}

.pagination,
.pagination p,
.pagination span,
.pagination div {
    font-family: 'Barlow Condensed' !important;
    font-size: 12px;
}

.thumnbail {
    max-height: 64px;
    max-width: 64px;
}

.documents h1 {
    top: -3px !important;
    padding-bottom: 0px;
    position: absolute;
}

@media only screen and (max-width: 600px) {
    .hideMobile {
        display: none !important;
    }

    .documents div {
        overflow: hidden !important;
    }

    .container {
        bottom: 19px;

    }

    .documents td,
    .documents th,
    .documents tbody {
        padding: 1px;
        line-height: 0.4;
        padding-left: 7px;
        padding-right: 7px;
        /* vertical-align: top; */
    }

    .documents th {
        display: none;
    }
    .divTitle {
        top: -20px !important;
        padding-bottom: 0px;
    }



}