h3 {
  margin-top: 5px;
  color: #be2126;
  font-size: 20px;
}

.control {
  display: inline-block;
}
.control button {
  font-size: 10px;
  line-height: 1.4;
  margin-top: 2px;
}

.configBody {
  display: grid;
  grid-template-columns: 263px 1fr 1fr 1fr 1fr;
}

.resizeModeTitle {
  color: #666666;
  font-size: 12px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.configTitle,
.codeTitle {
  font-weight: bold;
  font-weight: 14px;
  margin-bottom: 10px;
}

.codeTitle {
  margin-top: 20px;
}

.previewTitle {
  font-weight: bold;
  font-weight: 14px;
  margin-top: 14px;
}

.codeBody {
  zoom: 0.8;
}

.description {
  position: absolute;
  top: 46.5px;
  left: 87px;
  font-size: 13px;
}